/* eslint-disable import/prefer-default-export, react/prop-types */

import React from 'react';
import TopLayout from './src/components/TopLayout';
import Helmet from 'react-helmet';
require('prismjs/themes/prism-twilight.css');

export const wrapRootElement = ({ element }) => {
  return (
    <TopLayout>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      {element}
    </TopLayout>
  );
};
