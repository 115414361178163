import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

let themeOpts = {
  typography: {
    h1: {
      fontSize: 48,
      fontWeight: 500,
      marginTop: 16,
      marginBottom: 16,
    },
    h2: {
      marginTop: 32,
      marginBottom: 16,
      fontSize: 36,
      fontWeight: 500,
    },
    h3: {
      marginTop: 16,
      marginBottom: 16,
      fontSize: 24,
    },
    subtitle1: {
      marginTop: 16,
      marginBottom: 16,
      fontSize: 25,
    },
    body1: {
      fontSize: 20,
    },
    fontFamily: ['Montserrat', 'Helvetica', 'Roboto', 'Arial', 'sans-serif'],
  },
  palette: {
    primary: {
      light: '#8296b0',
      main: 'rgb(244,67,54)',
    },
    secondary: {
      main: '#fff',
    },
  },
};
let theme = createMuiTheme(themeOpts);
theme = responsiveFontSizes(theme, {
  factor: 2,
});

export { themeOpts };
export default theme;
